import React from 'react';
import './Hexagon.css';

const Hexagon = ({ isVisible, hero, artifact, isArtifactSlot }) => {
    if (isArtifactSlot) {
        if (artifact) {
            return <img src={`images/artifacts/${artifact}.webp`} alt={artifact} className="hexagon" />;
        }
        return <div className="hexagon artifact" />;
    }
    if (hero) {
        return <img src={`images/heroes/${hero.name}.webp`} alt={hero.name} className="hexagon" />;
    }
    return <div className="hexagon" style={{ opacity: isVisible ? 1 : 0 }} />;
};

export default Hexagon;
