import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { artifacts } from '../data';

function ArtifactSelector({ value, onChange }) {
    return (
        <FormControl fullWidth>
            <InputLabel id="artifact-label">Artifact</InputLabel>
            <Select defaultOpen value={value} onChange={onChange} fullWidth>
                <MenuItem value="">Select Artifact</MenuItem>
                {artifacts.map(artifact => (
                    <MenuItem key={artifact} value={artifact}>{artifact}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default ArtifactSelector;