import React, { useState } from 'react';
import { postBattle } from '../api';
import FormationBuilder from './FormationBuilder';
import { Button, MenuItem, Select, InputLabel, FormControl, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { arenas } from '../data';

function BattleForm({ setError, userToken }) {
    const [formData, setFormData] = useState({
        attackers: [],
        defenders: [],
        arena: 'Arena III',
        attackerArtifact: null,
        defenderArtifact: null
    });
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (combatSide, position, hero) => {
        setFormData(prevState => {
            const updatedCombatSide = prevState[combatSide].filter(h => h.position !== position);
            if (hero !== null) { // select hero
                updatedCombatSide.push({ ...hero, position });
            }
            return {
                ...prevState,
                [combatSide]: updatedCombatSide
            };
        });
    };

    const handleChangeArtifact = (combatSide, artifact) => {
        setFormData(prevState => ({
            ...prevState,
            [combatSide === 'attackers' ? 'attackerArtifact' : 'defenderArtifact']: artifact
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const attackers = formData.attackers.map(hero => ({ id: hero.id, position: hero.position }));
        const defenders = formData.defenders.map(hero => ({ id: hero.id, position: hero.position }));
        const newBattle = { attackers, defenders, arena: formData.arena, attackerArtifact: formData.attackerArtifact, defenderArtifact: formData.defenderArtifact };
        try {
            await postBattle(newBattle, userToken);
            setFormData({
                attackers: [],
                defenders: [],
                arena: 'Arena III',
                attackerArtifact: null,
                defenderArtifact: null
            });
            setSuccessMessage('Battle registered successfully!');
            setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <Box id="battle-form" sx={{ backgroundColor: 'rgb(240, 240, 240)', borderRadius: '10px', padding: '10px' }}>
            <Typography variant="h5" gutterBottom>Register Battle</Typography>
            {successMessage && <Typography variant="body1" color="success.main">{successMessage}</Typography>}
            <form id="add-battle-form" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid size={5}>
                        <Typography variant="h6">Attackers</Typography>
                        <FormationBuilder
                            positionedHeroes={formData.attackers}
                            handleChange={handleChange}
                            combatSide="attackers"
                            arena={formData.arena}
                            artifact={formData.attackerArtifact}
                            handleChangeArtifact={handleChangeArtifact}
                        />
                    </Grid>
                    <Grid size={2} container alignItems="center" justifyContent="center">
                        <Typography variant="h6">VS</Typography>
                    </Grid>
                    <Grid size={5}>
                        <Typography variant="h6">Defenders</Typography>
                        <FormationBuilder
                            positionedHeroes={formData.defenders}
                            handleChange={handleChange}
                            combatSide="defenders"
                            arena={formData.arena}
                            artifact={formData.defenderArtifact}
                            handleChangeArtifact={handleChangeArtifact}
                        />
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <FormControl fullWidth>
                        <InputLabel id="arena-label">Arena</InputLabel>
                        <Select
                            labelId="arena-label"
                            name="arena"
                            id="arena"
                            value={formData.arena}
                            onChange={(e) => setFormData({ ...formData, arena: e.target.value })}
                        >
                            {arenas.map(arena => (
                                <MenuItem key={arena} value={arena}>{arena}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box mt={2}>
                    <Button type="submit" variant="contained" color="primary">Submit</Button>
                </Box>
            </form>
        </Box>
    );
}

export default BattleForm;