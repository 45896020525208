import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import Hexagon from './Hexagon';
import { heroes } from '../data';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArtifactSelector from './ArtifactSelector';
import HeroSelector from './HeroSelector';

/**
 * 
 * @param {array} positionedHeroes - The current formation of heroes.
 * @param {function} handleChange - Function to handle changes in the formation.
 * @param {string} combatSide - The type of formation (attackers/defenders)
 * @param {string} arena - The name of the arena (e.g., 'Arena III').
 * @returns {React.Component}
 */
function FormationBuilder({ positionedHeroes, handleChange, combatSide, arena, artifact, handleChangeArtifact }) {
    const hexagonsInMap = 39;
    const hexagonIndexToPositionMap = {
        "Arena I": { 17:10, 18:12, 19:13, 22:5, 23:7, 24:9, 25:11, 28:2, 29:4, 30:6, 31:8, 35:1, 36:3},
        "Arena II": { 17:9, 18:10, 22:5, 23:7, 28:2, 29:4, 30:6, 31:8, 35:1, 36:3},
        "Arena III": { 6:10, 11:8, 18:7, 19:9, 22:4, 23:5, 24:6, 28:2, 29:3, 35:1 },
        "Arena IV": { 6:9, 11:7, 12:8, 24:6, 29:3, 30:4, 31:5, 35:1, 36:2 },
        "Arena V": { 6:11, 11:8, 17:6, 19:9, 22:4, 27:10, 28:2, 31:5, 32:7, 35:1, 36:3 }
    };

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedHero, setSelectedHero] = useState('');
    const [selectedArtifact, setSelectedArtifact] = useState('');

    function isArtifact(index) {
        if (combatSide === "defenders") {
            index = hexagonsInMap - 1 - index;
        }
        return index === 33;
    }

    function isVisible(index) {
        if (combatSide === "defenders") {
            index = hexagonsInMap - 1 - index;
        }
        return hexagonIndexToPositionMap[arena][index] !== undefined;
    }

    function getHero(index) {
        if (combatSide === "defenders") {
            index = hexagonsInMap - 1 - index;
        }
        const position = hexagonIndexToPositionMap[arena][index];
        return positionedHeroes.find(hero => hero.position === position);
    }

    const handleHexagonClick = (index) => {
        if (isArtifact(index) || isVisible(index)) {
            setSelectedIndex(index);
            setModalOpen(true);
        }
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedHero('');
        setSelectedArtifact('');
    };

    const handleSave = () => {
        if (isArtifact(selectedIndex)) {
            handleChangeArtifact(combatSide, selectedArtifact || null);
        } else {
            const index = combatSide === "defenders" ? hexagonsInMap - 1 - selectedIndex : selectedIndex;
            const position = hexagonIndexToPositionMap[arena][index];
            if (position !== undefined) {
                const hero = heroes.find(hero => hero.id === selectedHero);
                handleChange(combatSide, position, hero || null);
            }
        }
        handleModalClose();
    };

    return (
        <>
            <Grid container spacing={0} maxWidth="350px">
                {Array.from({ length: hexagonsInMap }).map((_, index) => (
                    <Grid key={index} size={2} style={{
                        ...(index > 5 ? { marginTop: '-4.85%' } : {}),
                        ...([6, 17, 28].includes(index) ? { marginLeft: '8.333%' } : {})
                    }} onClick={() => handleHexagonClick(index)}>
                        {(() => {
                            const hero = getHero(index);
                            if (hero) {
                                return <Hexagon isVisible={isVisible(index)} hero={hero} />;
                            } else {
                                return <Hexagon isVisible={isVisible(index)} isArtifactSlot={isArtifact(index)} artifact={artifact} />;
                            }
                        })()}
                    </Grid>
                ))}
            </Grid>
            <Modal open={modalOpen} onClose={handleModalClose}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 300, bgcolor: 'background.paper', p: 4, boxShadow: 24 }}>
                    {isArtifact(selectedIndex) ? (
                        <ArtifactSelector value={selectedArtifact} onChange={(e) => setSelectedArtifact(e.target.value)} />
                    ) : (
                        <HeroSelector value={selectedHero} onChange={(e) => setSelectedHero(e.target.value)} />
                    )}
                    <Button onClick={handleSave} variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }}>Save</Button>
                </Box>
            </Modal>
        </>
    );
}

export default FormationBuilder;