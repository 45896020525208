import React, { useState, useEffect } from 'react';
import BattleForm from './components/BattleForm';
import BattleList from './components/BattleList';
import FormationSearch from './components/FormationSearch';
import { Container, Typography, AppBar, Toolbar, Button, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { postBattleReaction, getUserReactions } from './api';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import ErrorModal from './components/ErrorModal';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpDialog from './components/HelpDialog';

function App() {
    const [ battles, setBattles ] = useState([]);
    const [ userReactions, setUserReactions ] = useState({}); // dict with battleId as key and reaction as value: {1: 1, 2: -1}
    const [ showBattleForm, setShowBattleForm ] = useState(false);
    const [ userToken, setUserToken ] = useState(null);
    const [ profile, setProfile ] = useState(null);
    const [error, setError] = useState(null);
    const [helpOpen, setHelpOpen] = useState(false);

    useEffect(() => {
        const storedToken = localStorage.getItem('userToken');
        if (storedToken) {
            try {
                const decoded = jwtDecode(storedToken);
                const currentTime = Date.now() / 1000;
                if (decoded.exp > currentTime) {
                    setUserToken(storedToken);
                    setProfile(decoded);
                } else {
                    // TODO: At some point handle token refresh automatically
                    localStorage.removeItem('userToken');
                }
            } catch (error) {
                console.error('Error decoding token:', error);
                localStorage.removeItem('userToken');
            }
        }
        const isFirstVisit = localStorage.getItem('isFirstVisit');
        if (!isFirstVisit) {
            setHelpOpen(true);
            localStorage.setItem('isFirstVisit', 'false');
        }
    }, []);

    useEffect(() => {
        if (userToken) {
            const fetchUserReactions = async () => {
                const reactionsArray = await getUserReactions(userToken);
                const reactionsDict = reactionsArray.reduce((acc, { battleId, reaction }) => {
                    acc[battleId] = reaction;
                    return acc;
                }, {});
                setUserReactions(reactionsDict);
            };
            fetchUserReactions();
        }
        else{
            setUserReactions({});
        }
    }, [userToken]);

    const handleLogin = (response) => {
        const token = response.credential;
        try {
            const decoded = jwtDecode(token);
            setUserToken(token);
            setProfile(decoded);
            localStorage.setItem('userToken', token);
        } catch (error) {
            console.error('Error decoding token after login:', error);
        }
    };

    const handleLogout = () => {
        googleLogout();
        setProfile(null);
        setUserToken(null);
        localStorage.removeItem('userToken');
    };

    const handleSearchResults = (searchResults) => {
        setBattles(searchResults);
    };

    const handleUserReaction = async (battleId, reaction) => {
        try {
            var result = await postBattleReaction(battleId, reaction, userToken);
            if (result !== undefined) {
                const oldReaction = userReactions[battleId];

                const propToUpdate = reaction === 1 ? 'likes' : 'dislikes';
                if (oldReaction){
                    if (oldReaction === reaction){
                        // User is removing their reaction
                        setBattles(battles.map(battle =>
                            battle.id === battleId
                                ? { ...battle, [propToUpdate]: battle[propToUpdate] - 1 }
                                : battle
                        ));
                        setUserReactions({ ...userReactions, [battleId]: null });
                    }
                    else{
                        // User is changing their reaction
                        const propToDecrease = propToUpdate === 'likes' ? 'dislikes' : 'likes';
                        setBattles(battles.map(battle =>
                            battle.id === battleId
                                ? { ...battle, [propToDecrease]: battle[propToDecrease] - 1, [propToUpdate]: battle[propToUpdate] + 1 }
                                : battle
                        ));
                        setUserReactions({ ...userReactions, [battleId]: reaction });
                    }
                }
                else{
                    // User is adding a reaction
                    setBattles(battles.map(battle =>
                        battle.id === battleId
                            ? { ...battle, [propToUpdate]: battle[propToUpdate] + 1 }
                            : battle
                    ));
                    setUserReactions({ ...userReactions, [battleId]: reaction });
                }
            }
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <React.Fragment>
            <AppBar position="static">
                <Container maxWidth="lg">
                    <Toolbar disableGutters>
                        <Typography variant="h6" style={{ flexGrow: 1, textAlign: 'left' }}>
                            AFKJ Arena DB
                        </Typography>
                        <IconButton color="inherit" onClick={() => setHelpOpen(true)}>
                            <HelpOutlineIcon />
                        </IconButton>
                        {profile ? (
                            <div>
                                <Button color="inherit" onClick={handleLogout}>Log out {profile.name}</Button>
                            </div>
                        ) : (
                            <GoogleLogin
                                onSuccess={handleLogin}
                                onError={error => console.error('Login Failed:', error)}
                            />
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid size={12} marginTop={2}>
                        <Button variant="contained" color="primary" onClick={() => setShowBattleForm(!showBattleForm)}>
                            {showBattleForm ? 'Hide Form' : 'Upload a Formation'}
                        </Button>
                        {showBattleForm && <BattleForm setError={setError} userToken={userToken} />}
                    </Grid>
                    <Grid size={12}>
                        <FormationSearch onSearchResults={handleSearchResults} />
                    </Grid>
                    <Grid size={12}>
                        <Typography variant="h5" gutterBottom>Search Results</Typography>
                        <BattleList battles={battles} onUserReaction={handleUserReaction} userReactions={userReactions} />
                    </Grid>
                </Grid>
            </Container>
            <ErrorModal open={!!error} handleClose={() => setError(null)} errorMessage={error} />
            <HelpDialog open={helpOpen} onClose={() => setHelpOpen(false)} />
        </React.Fragment>
    );
}

export default App;
