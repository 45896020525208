import React from 'react';
import Battle from './Battle';
import { Box } from '@mui/material';

function BattleList({battles, onUserReaction, userReactions}) {
    return (
        <Box id="battles">
            {battles.map(battle => (
                <Battle key={battle.id} battle={battle} onUserReaction={onUserReaction} userReactions={userReactions} />
            ))}
        </Box>
    );
}

export default BattleList;