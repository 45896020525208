import React from 'react';
import { Dialog, DialogContent, DialogContentText, Typography } from '@mui/material';

const HelpDialog = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="h4">Welcome to the AFK Journey Arena DB!</Typography>
                    <p>
                        This is a tool for the Arena PVP mode in AFK Journey that lets you easily share formations you have used to beat 
                        other players' defensive formations, and search for formations that other players have used to beat a specific formation.
                        <br />
                        Users can also contribute by liking/disliking formations to help others find the best formations and help weed out the bad ones.
                    </p>
                    <Typography variant="h6">FAQs</Typography>
                    <p><strong>How do I upload a formation?</strong></p>
                    <p>
                        <ol>
                            <li>First, you must log in with your Google account</li>
                            <li>Click on the "Upload a Formation" button</li>
                            <li>Choose the arena you want to upload the formation for</li>
                            <li>Choose the artifact/spell and heroes for both sides by clicking on the hexagons. The orange hexagon is for the artifact.</li>
                            <li>Click on the "Submit" button</li>
                        </ol>
                    </p>
                    <p><strong>How do I search for formations?</strong></p>
                    <p>
                        <ol>
                            <li>Choose the arena you want to search for formations in</li>
                            <li>
                                Choose the artifact/spell and heroes for both sides by clicking on the hexagons. The orange hexagon is for the artifact. 
                                <strong>You can search without choosing a full 5 heroes to get more results</strong>
                            </li>
                            <li>Click on the "Search" button</li>
                            <li>Any formations that match your search criteria will be displayed, ordered by rating</li>
                        </ol>
                    </p>
                    <p><strong>Why do I have to log in with Google?</strong></p>
                    <p>
                        Without requiring login, the risk of abuse by liking bad formations or disliking good formations is higher.
                        Google login is free and easy to implement, and most people have a Google account, so I went with it.
                    </p>
                    <p><strong>Will you continue to add new heroes as they release?</strong></p>
                    <p>
                        I can't promise anything since I have limited time to work on this, but as long as I continue to play the game
                        I will try to add new heroes to the tool as they are released.
                        If AFK Analytica wants to take over the project and incorporate it into their site, I would be happy to hand it over.
                    </p>
                    <p><strong>Why is the UI so bad on mobile?</strong></p>
                    <p>
                        I am aware, sorry! I am not a UI/UX designer, and I usually only do backend development.
                        If I have the time and motivation, I may try to improve it.
                    </p>
                    <p><strong>Why does nothing work?</strong></p>
                    <p>
                        This project is hosted on Cloudflare's free tier, which has many limitations on resources, so it may be slow or unresponsive.
                        Since this is a side project and I don't make any money from it, I currently have no plans to upgrade to a paid plan.
                    </p>
                    {/* Add more FAQs as needed */}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default HelpDialog;
