import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

function ErrorModal({ open, handleClose, errorMessage }) {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 300, bgcolor: 'background.paper', p: 4, boxShadow: 24 }}>
                <Typography variant="h6" gutterBottom>Error</Typography>
                <Typography variant="body1" gutterBottom>{errorMessage}</Typography>
                <Button onClick={handleClose} variant="contained" color="primary" fullWidth>Close</Button>
            </Box>
        </Modal>
    );
}

export default ErrorModal;
