import React from 'react';
import { Typography, Box, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import FormationViewer from './FormationViewer';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

function Battle({ battle, onUserReaction, userReactions }) {
    return (
        <Box className="battle" data-battle-id={battle.id} p={2} mb={2} border={1} borderRadius={2}>
            <Grid container spacing={2} textAlign="center">
                <Grid size={5}>
                    <Typography variant="h6">Attackers</Typography>
                    <FormationViewer positionedHeroes={battle.attackers} combatSide="attackers" arena={battle.arena} artifact={battle.attackerArtifact} />
                </Grid>
                <Grid size={2} container alignItems="center" justifyContent="center">
                    <Typography variant="h6">VS</Typography>
                </Grid>
                <Grid size={5}>
                    <Typography variant="h6">Defenders</Typography>
                    <FormationViewer positionedHeroes={battle.defenders} combatSide="defenders" arena={battle.arena} artifact={battle.defenderArtifact} />
                </Grid>
            </Grid>
            <Box mt={2} display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <IconButton color="primary" onClick={() => onUserReaction(battle.id, 1)}>
                        {userReactions[battle.id] === 1 ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />}
                    </IconButton>
                    <Typography variant="body1"><strong>{battle.likes - battle.dislikes}</strong></Typography>
                    <IconButton color="secondary" onClick={() => onUserReaction(battle.id, -1)}>
                        {userReactions[battle.id] === -1 ? <ThumbDownAltIcon /> : <ThumbDownOffAltIcon />}
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
}

export default Battle;