import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { heroes } from '../data';

function HeroSelector({ value, onChange }) {
    return (
        <FormControl fullWidth>
            <InputLabel id="hero-label">Hero</InputLabel>
            <Select defaultOpen value={value} onChange={onChange} fullWidth>
                <MenuItem value="">None</MenuItem>
                {heroes.map(hero => (
                    <MenuItem key={hero.id} value={hero.id}>{hero.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default HeroSelector;