async function send(method, path, body = null, token = null) {
    const useMock = process.env.NODE_ENV === 'development';
    if (useMock) {
        const responseBody = require(`../functions${path}.json`);
        console.log(responseBody);
        return responseBody;
    }
    const options = {
        method,
        headers: { 'Content-Type': 'application/json' },
    };
    if (token) {
        options.headers.Authorization = `Bearer ${token}`;
    }
    if (body) {
        options.body = JSON.stringify(body);
    }

    const response = await fetch(path, options);
    if (response.status === 204) {
        return null; // No content to parse
    }
    if (response.ok){
        try {
            const responseBody = await response.json();
            return responseBody;
        } catch (error) {
            console.error('Error parsing data:', error);
            throw new Error("Oops! Something went wrong, try again later.");
        }
    }
    const errorText = await response.text();
    if (response.status === 401) {
        throw new Error("You must be logged in to do that. If you already are, try logging out and back in.");
    }
    if (response.status >= 400 && response.status < 500) {
        throw new Error(errorText);
    }
    throw new Error("Oops! Something went wrong, try again later.");
}

async function get(path, token = null) {
    return await send('GET', path, null, token);
}

async function put(path, data, token = null) {
    return await send('PUT', path, data, token);
}

async function post(path, data, token = null) {
    return await send('POST', path, data, token);
}

// Battles
async function postBattleReaction(battleId, reaction, token) { // TODO: This makes more sense as POST /user/reactions/:battleId
    if (reaction !== 1 && reaction !== -1) {
        throw new Error('Invalid action. Action must be 1 or -1.');
    }
    const url = `/api/battles/${battleId}`;
    const data = { reaction: reaction };
    return await put(url, data, token);
}

async function postBattle(battle, token) {
    return await post('/api/battles', battle, token);
}

async function searchByDefenders(arena, defenders, artifact) {
    return await post('/api/battles/search', { arena, defenders, artifact });
}

// User
async function getUserReactions(token) {
    return await get('/api/user/reactions', token);
}

export { postBattleReaction, postBattle, searchByDefenders, getUserReactions };