import React, { useState } from 'react';
import { searchByDefenders } from '../api';
import FormationBuilder from './FormationBuilder';
import { Box, Button, MenuItem, Select, InputLabel, FormControl, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { arenas } from '../data';

function FormationSearch({ onSearchResults }) {
    const [formData, setFormData] = useState({
        positionedHeroes: [],
        arena: 'Arena III',
        artifact: null
    });

    const handleChangeHeroes = (_, position, hero) => {
        setFormData(prevState => {
            const updatedFormation = prevState.positionedHeroes.filter(h => h.position !== position);
            if (hero !== null) { // select hero
                updatedFormation.push({ ...hero, position });
            }
            return {
                ...prevState,
                positionedHeroes: updatedFormation
            };
        });
    };

    const handleChangeArtifact = (_, artifact) => {
        setFormData(prevState => ({ ...prevState, artifact }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const searchResults = await searchByDefenders(formData.arena, formData.positionedHeroes, formData.artifact);
        onSearchResults(searchResults);
    };

    return (
        <Box id="search-battle-container">
            <form id="search-battle-form" onSubmit={handleSubmit}>
                <Grid container spacing={2} marginTop={2}>
                    <Grid size={6}>
                        <Typography variant="h5" gutterBottom>Search for counters to a defending formation</Typography>
                        <FormControl fullWidth sx={{ marginTop: 2 }}>
                            <InputLabel id="arena-label">Map</InputLabel>
                            <Select
                                labelId="arena-label"
                                name="arena"
                                id="arena"
                                value={formData.arena}
                                onChange={(e) => setFormData({ ...formData, arena: e.target.value, positionedHeroes: [] })}
                            >
                                {arenas.map(arena => (
                                    <MenuItem key={arena} value={arena}>{arena}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 2 }}>Search</Button>
                    </Grid>
                    <Grid size={6}>
                        <FormationBuilder
                            positionedHeroes={formData.positionedHeroes}
                            handleChange={handleChangeHeroes}
                            combatSide="defenders"
                            arena={formData.arena}
                            artifact={formData.artifact}
                            handleChangeArtifact={handleChangeArtifact}
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

export default FormationSearch;