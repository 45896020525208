import React from 'react';
import Grid from '@mui/material/Grid2';
import Hexagon from './Hexagon';

// TODO: This is a duplicate of FormationBuilder.js - refactor to a common component with a prop to toggle editable true/false
/**
 * 
 * @param positionedHeroes - [{"id":1,"name":"Thoran","position":10}, ...]
 * @returns 
 */
function FormationViewer({ positionedHeroes, combatSide, arena, artifact }) {
    const hexagonsInMap = 39;
    const hexagonIndexToPositionMap = {
        "Arena I": { 17:10, 18:12, 19:13, 22:5, 23:7, 24:9, 25:11, 28:2, 29:4, 30:6, 31:8, 35:1, 36:3},
        "Arena II": { 17:9, 18:10, 22:5, 23:7, 28:2, 29:4, 30:6, 31:8, 35:1, 36:3},
        "Arena III": { 6:10, 11:8, 18:7, 19:9, 22:4, 23:5, 24:6, 28:2, 29:3, 35:1 },
        "Arena IV": { 6:9, 11:7, 12:8, 24:6, 29:3, 30:4, 31:5, 35:1, 36:2 },
        "Arena V": { 6:11, 11:8, 17:6, 19:9, 22:4, 27:10, 28:2, 31:5, 32:7, 35:1, 36:3 }
    };

    function isVisible(index) {
        if (combatSide === "defenders") {
            // The indexes counted from the end of the array for defending side
            index = hexagonsInMap-1-index;
        }
        return hexagonIndexToPositionMap[arena][index] !== undefined;
    }
    function getHero(index) {
        if (combatSide === "defenders") {
            index = hexagonsInMap-1-index;
        }
        const position = hexagonIndexToPositionMap[arena][index];
        return positionedHeroes.find(hero => hero.position === position);
    }
    function isArtifact(index) {
        if (combatSide === "defenders") {
            index = hexagonsInMap-1-index;
        }
        return index === 33;
    }

    return (
        <Grid container spacing={0} maxWidth="350px">
            {Array.from({ length: hexagonsInMap }).map((_, index) => (
                <Grid key={index} size={2} style={{
                    ...(index > 5 ? { marginTop: '-4.85%' } : {}), // No idea how to calculate this, trial and error :)
                    ...([6, 17, 28].includes(index) ? { marginLeft: '8.333%' } : {}) // 50 / 6
                }}>
                    {(() => {
                        const hero = getHero(index);
                        if (hero) {
                            return <Hexagon isVisible={isVisible(index)} hero={hero}/>;
                        } else {
                            return <Hexagon isVisible={isVisible(index)} artifact={artifact} isArtifactSlot={isArtifact(index)}/>;
                        }
                    })()}
                </Grid>
            ))}
        </Grid>
    );
}

export default FormationViewer;