// TODO: Figure out if the react app and this cloudflare worker could share the same data.js file.
// NB: Any changes here should also be made exactly in ./src/data.js
const heroes = [
    { id: 1, name: 'Antandra' },
    { id: 2, name: 'Arden' },
    { id: 3, name: 'Atalanta' },
    { id: 4, name: 'Berial' },
    { id: 5, name: 'Brutus' },
    { id: 6, name: 'Bryon' },
    { id: 7, name: 'Carolina' },
    { id: 8, name: 'Cassadee' },
    { id: 9, name: 'Cecia' },
    { id: 10, name: 'Damian' },
    { id: 11, name: 'Dionel' },
    { id: 12, name: 'Eironn' },
    { id: 13, name: 'Fay' },
    { id: 14, name: 'Granny Dahnie' },
    { id: 15, name: 'Hewynn' },
    { id: 16, name: 'Igor' },
    { id: 17, name: 'Kafra' },
    { id: 18, name: 'Koko' },
    { id: 19, name: 'Korin' },
    { id: 20, name: 'Kruger' },
    { id: 21, name: 'Lucius' },
    { id: 22, name: 'Lumont' },
    { id: 23, name: 'Lyca' },
    { id: 24, name: 'Marilee' },
    { id: 25, name: 'Mirael' },
    { id: 26, name: 'Niru' },
    { id: 27, name: 'Odie' },
    { id: 28, name: 'Parisa' },
    { id: 29, name: 'Reinier' },
    { id: 30, name: 'Rhys' },
    { id: 31, name: 'Rowan' },
    { id: 32, name: 'Salazer' },
    { id: 33, name: 'Satrana' },
    { id: 34, name: 'Scarlita' },
    { id: 35, name: 'Seth' },
    { id: 36, name: 'Shakir' },
    { id: 37, name: 'Silvina' },
    { id: 38, name: 'Smokey & Meerky' },
    { id: 39, name: 'Temesia' },
    { id: 40, name: 'Thoran' },
    { id: 41, name: 'Valen' },
    { id: 42, name: 'Viperian' },
    { id: 43, name: 'Walker' },
    { id: 44, name: 'Vala' },
    { id: 45, name: 'Florabelle' },
    { id: 46, name: 'Alsa' },
    { id: 47, name: 'Soren' },
    { id: 48, name: 'Phraesto' },
    { id: 49, name: 'Phraesto (Clone)' },
    { id: 50, name: 'Ulmus' },
    { id: 51, name: 'Talene' },
    { id: 52, name: 'Ludovic' },
    { id: 53, name: 'Lily May' },
    { id: 54, name: 'Mikola' },
    { id: 55, name: 'Lenya' },
    { id: 56, name: 'Dunlingr' },
    { id: 57, name: 'Sinbad' },
    { id: 58, name: 'Nara' },
];

const artifacts = [
    'Awakening',
    'Ironwall',
    'Enlightening',
    'Starshard',
    'Confining',
    'Blazing',
];

const arenas = [
    'Arena I',
    'Arena II',
    'Arena III',
    'Arena IV',
    'Arena V',
];

export { heroes, artifacts, arenas };